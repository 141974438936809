import React, { useState, useEffect } from "react";
import axios from "axios";

import ScheduleReg from "./Components/ScheduleReg";
import Uploadpdf from "./Components/uploadpdf";
import UploadStandings from "./Components/UploadStandings";
import { EditSchedule } from "./Components/EditSchedule";

import { BASE_URL, API_URLS } from "../../../Utils";

const Schedule = () => {
  const [selectedYear, setSelectedYear] = useState("2024");
  const [tournamentList, setTournamentList] = useState([]);
  const [allParkSchedule, setAllParkSchedule] = useState([]);
  const [showSchedule, setShowSchedule] = useState(false);
  const [UploadPdf, setUploadPdf] = useState(false);
  const [UploadStanding, setUploadStanding] = useState(false);
  const [showEditSchedule, setshowEditSchedule] = useState(false);
  const [selectTournament, setSelectTournament] = useState("");
  const [ScheduleID, setScheduleID] = useState(null);

  const formatDate = (dateString) => {
    const options = { month: "long", day: "numeric", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  const openScheduleModal = () => {
    setShowSchedule(true);
  };
  const closeScheduleModal = () => {
    setShowSchedule(false);
  };

  const openUploadPdf = () => {
    setUploadPdf(true);
  };
  const closeUploadPdf = () => {
    setUploadPdf(false);
  };
  const openUploadStanding = () => {
    setUploadStanding(true);
  };
  const closeUploadStanding = () => {
    setUploadStanding(false);
  };

  const openEditScheduleModal = (ScheduleID) => {
    setshowEditSchedule(true);
  };
  const closeEditScheduleModal = () => {
    setshowEditSchedule(false);
  };

  const fnTournamentList = () => {
    const url = `${BASE_URL}/api/v1/web/park/get_all_proam_tournaments_by_year?year=${selectedYear}`;
    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        setTournamentList(data);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const getAllParkSchedule = (ParkId, FirstName) => {
    const url = `${BASE_URL}/api/v1/web/park/get_all_park_schedules?ParkId=${ParkId}&SeasonOrTour=${FirstName}&Year=${selectedYear}`;

    fetch(url)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        let retrievedData = data.data.result;

        // Format the Time field
        retrievedData = retrievedData.map((item) => ({
          ...item,
          Time: formatTime(item.Time),
        }));

        // Sort first by day of the week, then by time
        const sortedData = sortByDayAndTime(retrievedData);

        setAllParkSchedule(sortedData);
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const formatTime = (time) => {
    let [hourPart, minutePart] = time.split(":");
    let minutes, period;

    if (minutePart) {
      [minutes, period] = minutePart.split(" ");
      period = period ? period.toUpperCase() : "AM";
    } else {
      minutes = "00";
      period = "AM";
    }

    let hours = parseInt(hourPart, 10);

    if (period !== "AM" && period !== "PM") {
      period = hours >= 12 ? "PM" : "AM";
    }

    if (hours > 12) {
      hours -= 12;
      period = "PM";
    } else if (hours === 12) {
      period = period === "AM" ? "PM" : "AM";
    } else if (hours === 0) {
      hours = 12;
      period = "AM";
    }

    hours = String(hours).padStart(2, "0");
    minutes = minutes.padStart(2, "0");

    return `${hours}:${minutes} ${period}`;
  };

  const compareTime = (timeA, timeB) => {
    const [hoursA, minutesA, periodA] = timeA
      .match(/(\d+):(\d+) (AM|PM)/)
      .slice(1);
    const [hoursB, minutesB, periodB] = timeB
      .match(/(\d+):(\d+) (AM|PM)/)
      .slice(1);

    const dateA = new Date();
    const dateB = new Date();

    dateA.setHours(
      periodA === "PM" && hoursA !== "12"
        ? parseInt(hoursA, 10) + 12
        : hoursA === "12"
        ? 12
        : parseInt(hoursA, 10)
    );
    dateA.setMinutes(parseInt(minutesA, 10));

    dateB.setHours(
      periodB === "PM" && hoursB !== "12"
        ? parseInt(hoursB, 10) + 12
        : hoursB === "12"
        ? 12
        : parseInt(hoursB, 10)
    );
    dateB.setMinutes(parseInt(minutesB, 10));

    return dateA - dateB;
  };

  const sortByDayAndTime = (data) => {
    const daysOfWeekOrder = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    return data.sort((a, b) => {
      const dayComparison =
        daysOfWeekOrder.indexOf(a.DayOfWeek) -
        daysOfWeekOrder.indexOf(b.DayOfWeek);
      if (dayComparison === 0) {
        return compareTime(a.Time, b.Time);
      }
      return dayComparison;
    });
  };

  const handleTournamentChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const ParkId = selectedOption.value;
    const FirstName = selectedOption.textContent;
    setSelectTournament(ParkId);
    getAllParkSchedule(ParkId, FirstName);
  };

  const handleEdit = (ScheduleID) => {
    openEditScheduleModal(true);
    setScheduleID(ScheduleID);
  };

  const deleteSchedulebyId = async (ScheduleID) => {
    try {
      const response = await axios.delete(API_URLS.DELETE_SCHEDULE_BY_ID, {
        params: { ScheduleId: ScheduleID },
      });
      if (response.status === 200 && response.data.status === "success") {
        if (selectTournament) {
          // Get the selected tournament's details
          const selectedTournamentDetails = tournamentList.data.result.find(
            (tournament) => tournament.ParkId.toString() === selectTournament
          );
          if (selectedTournamentDetails) {
            getAllParkSchedule(
              selectTournament,
              selectedTournamentDetails.FirstName
            );
          }
        }
      } else {
        console.error("Error deleting Schedule:", response.data);
      }
    } catch (error) {
      console.error("Error deleting Schedule:", error);
    }
  };

  const handleDelete = (ScheduleID, GameNumber) => {
    const confirmDelete = window.confirm(
      `Are You Surely Want to Delete- "GAME NUMBER :  ${GameNumber} ??"`
    );
    if (confirmDelete) {
      deleteSchedulebyId(ScheduleID);
    }
  };

  const refreshSchedule = () => {
    if (selectTournament) {
      const selectedTournamentDetails = tournamentList.data.result.find(
        (tournament) => tournament.ParkId.toString() === selectTournament
      );
      if (selectedTournamentDetails) {
        getAllParkSchedule(
          selectTournament,
          selectedTournamentDetails.FirstName
        );
      }
    }
  };

  useEffect(() => {
    fnTournamentList();
  }, [selectedYear]);

  return (
    <div className="container" style={{ maxWidth: "85%" }}>
      <div className="tabs_wrapper" data-aos="fade" data-aos-delay="50">
        <h2 style={{ color: "black", fontWeight: "bold", textAlign: "center" }}>
          Manage Schedule & Upload Standings
        </h2>
        <div
          className="table_wrap"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              gap: "8px",
            }}
          >
            <div className="col-md-2">
              <select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
                className="selection"
              >
                {/* <select value="2024" className="selection"> */}
                <option value="2018">2018</option>
                <option value="2019">2019</option>
                <option value="2020">2020</option>
                <option value="2021">2021</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
              </select>
            </div>
            <div className="col-md-6 ">
              <select
                id="selectedTournament"
                value={selectTournament}
                onChange={handleTournamentChange}
                className="selection"
              >
                <option>Select Tournament</option>
                {tournamentList.data?.result.map((tournament) => (
                  <option key={tournament.ParkId} value={tournament.ParkId}>
                    {tournament.FirstName}
                  </option>
                ))}
              </select>
              {/* <select name="" id="" className="selection">
                  <option value="">Select Tournament</option>
                  <option value="">Tournament 02</option>
                  <option value="">Tournament 03</option>
                </select> */}
            </div>
          </div>
          <div className="tab_wrap">
            <button
              className="btn btn_primary text_upp"
              onClick={openScheduleModal}
            >
              Register Schedule
            </button>
            {showSchedule && (
              <ScheduleReg
                isOpen={showSchedule}
                onClose={closeScheduleModal}
                onAdd={refreshSchedule}
              />
            )}
            <button
              className="btn btn_primary text_upp "
              onClick={openUploadPdf}
            >
              Upload Schedule PDF
            </button>
            {UploadPdf && (
              <Uploadpdf
                isOpen={UploadPdf}
                onClose={closeUploadPdf}
                ParkId={selectTournament}
              />
            )}
            <button
              className="btn btn_primary text_upp "
              onClick={openUploadStanding}
            >
              Upload Standings
            </button>
            {UploadStanding && (
              <UploadStandings
                isOpen={UploadStanding}
                onClose={closeUploadStanding}
                ParkId={selectTournament}
              />
            )}
          </div>
        </div>
        <div
          className="tabs_container"
          style={{ overflowY: "auto", maxHeight: "500px" }}
        >
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              tableLayout: "fixed", // Ensures equal width distribution
            }}
          >
            <thead style={{ position: "sticky", top: 0, background: "#fff" }}>
              <tr>
                <th style={{ width: "14.29%", textAlign: "center" }}>
                  Day Of Week
                </th>
                <th style={{ width: "14.29%", textAlign: "center" }}>Time</th>
                <th style={{ width: "14.29%", textAlign: "center" }}>
                  Visitor Team
                </th>
                <th style={{ width: "14.29%", textAlign: "center" }}>
                  Home Team
                </th>
                <th style={{ width: "14.29%", textAlign: "center" }}>
                  Playing Field
                </th>
                <th style={{ width: "14.29%", textAlign: "center" }}>Edit</th>
                <th style={{ width: "14.29%", textAlign: "center" }}>Delete</th>
              </tr>
            </thead>
            <tbody>
              {allParkSchedule.map((schedule) => (
                <tr key={schedule.ScheduleID}>
                  <td style={{ textAlign: "center" }}>{schedule.DayOfWeek}</td>
                  <td style={{ textAlign: "center" }}>{schedule.Time}</td>
                  <td style={{ textAlign: "center" }}>
                    {schedule.VisitorTeam}
                  </td>
                  <td style={{ textAlign: "center" }}>{schedule.HomeTeam}</td>
                  <td style={{ textAlign: "center" }}>
                    {schedule.FieldNumber}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <button
                      className=""
                      style={{
                        backgroundColor: "#fe5900",
                        width: "100%",
                        height: "44.59px",
                        color: "#fff",
                        border: "1px solid",
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        borderRadius: "0.4rem",
                        fontFamily: "Poppins",
                        transition: "background-color 0.3s, color 0.3s",
                        cursor: "pointer",
                      }}
                      onClick={() => handleEdit(schedule.ScheduleID)}
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#fff";
                        e.target.style.color = "#fe5900";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#fe5900";
                        e.target.style.color = "#fff";
                      }}
                    >
                      Edit
                    </button>
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <button
                      className=""
                      style={{
                        backgroundColor: "#fe5900",
                        width: "100%",
                        height: "44.59px",
                        color: "#fff",
                        border: "1px solid",
                        fontSize: "0.9rem",
                        fontWeight: "600",
                        borderRadius: "0.4rem",
                        fontFamily: "Poppins",
                        transition: "background-color 0.3s, color 0.3s",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleDelete(schedule.ScheduleID, schedule.GameNumber)
                      }
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#fff";
                        e.target.style.color = "#fe5900";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#fe5900";
                        e.target.style.color = "#fff";
                      }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {showEditSchedule && (
          <EditSchedule
            isOpen={showEditSchedule}
            onClose={closeEditScheduleModal}
            ScheduleID={ScheduleID}
            onUpdate={refreshSchedule}
          />
        )}
      </div>
    </div>
  );
};

export { Schedule };
