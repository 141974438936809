import React, { useEffect, useState } from "react";
import axios from "axios";

import RegWinner from "./Components/RegWinner";

import { API_URLS } from "../../../Utils";

const ManageBrackets = () => {
  const [allTournaments, setAllTournaments] = useState([]);
  const [bracketWinner, setAllBracketWinner] = useState([]);
  const [selectedParkId, setSelectedParkId] = useState(null);
  const [regModal, setRegModal] = useState(false);
  const [selectedWinnerID, setSelectedWinnerID] = useState(null);

  const getAllProAmTournaments = async () => {
    try {
      const {
        data: { data },
      } = await axios.get(API_URLS.GET_ALL_50_TOURNAMENTS);
      setAllTournaments(data?.result[0]);
      const resultArray = data?.result;
      const firstResult =
        Array.isArray(resultArray) && Array.isArray(resultArray[0])
          ? resultArray[0][0]
          : null;
      const id = firstResult ? firstResult.ParkId : undefined;
      if (id) {
        getAllBracketWinnerByParkId(id);
      }
    } catch (error) {
      alert(error.response.data.message);
    }
  };

  const getAllBracketWinnerByParkId = async (id) => {
    try {
      const response = await axios.get(API_URLS.GET_BRACKET_WINNER_BY_PARKID, {
        params: { id },
      });
      setAllBracketWinner(response.data.data.result);
    } catch (error) {
      // console.error("Error fetching data:", error);
    }
  };

  const handleSelectChange = (event) => {
    const selectedId = event.target.value;
    setSelectedParkId(selectedId);
    getAllBracketWinnerByParkId(selectedId);
  };

  const handleEditClick = (BracketWinnerID) => {
    setSelectedWinnerID(BracketWinnerID);
    setRegModal(true);
  };

  const deleteBracketWinnerbyId = async (BracketWinnerID, id) => {
    try {
      const response = await axios.delete(
        API_URLS.DELETE_BRACKET_WINNER_BY_ID,
        {
          params: { BracketWinnerID },
        }
      );
      if (response.status === 200 && response.data.status === "success") {
        getAllBracketWinnerByParkId(id);
      } else {
        // console.error("Error deleting Schedule:", response.data);
      }
    } catch (error) {
      // console.error("Error deleting Schedule:", error);
    }
  };

  const handleDeleteClick = (BracketWinnerID) => {
    const currentParkId = selectedParkId;
    const confirmDelete = window.confirm("confirm Delete ?");
    if (confirmDelete) {
      deleteBracketWinnerbyId(BracketWinnerID, currentParkId);
    }
  };

  const openRegModal = () => {
    setRegModal(true);
  };

  const closeRegModal = () => {
    setRegModal(false);
    setSelectedWinnerID(null);
  };

  useEffect(() => {
    getAllProAmTournaments();
  }, []);

  return (
    <div className="container">
      <div className="site-content">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <h2 style={{ color: "black", fontWeight: "bold" }}>
            Bracket Winner Management
          </h2>
          {selectedParkId && (
            <button
              className="btn btn_primary text_upp"
              onClick={() => openRegModal()}
            >
              Register Winner
            </button>
          )}
          {regModal && <RegWinner isOpen={regModal} onClose={closeRegModal} />}
        </div>
        <div style={{ marginTop: "3%" }}>
          <center>
            <p>
              <div htmlFor="selectedTournament">
                Select Park
                <span className="edd-required-indicator">*</span>
              </div>
              <select
                id="selectedTournament"
                style={{ width: "25%" }}
                onChange={handleSelectChange}
                className="selection"
              >
                <option value="" disabled selected>
                  Please select a tournament from the list
                </option>
                {allTournaments &&
                  allTournaments.map((tournament) => (
                    <option key={tournament.ParkId} value={tournament.ParkId}>
                      {tournament.FirstName}
                    </option>
                  ))}
              </select>
            </p>
          </center>
        </div>
        <div className="tabs_container">
          <table>
            <thead>
              <tr>
                <th>Bracket Winner</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {bracketWinner.map((winner) => (
                <tr key={winner.BracketWinnerID}>
                  <td>{winner.Detail}</td>
                  <td>
                    <button
                      className="btn btn_primary text_upp"
                      onClick={() => handleEditClick(winner.BracketWinnerID)}
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn btn_primary text_upp"
                      onClick={() => handleDeleteClick(winner.BracketWinnerID)}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {regModal && (
        <RegWinner
          isOpen={regModal}
          onClose={closeRegModal}
          BracketWinnerID={selectedWinnerID}
          ParkId={selectedParkId}
          refreshbracket={getAllBracketWinnerByParkId}
        />
      )}
    </div>
  );
};

export default ManageBrackets;
