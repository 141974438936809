import React, { useEffect, useState } from "react";
import axios from "axios";

import { API_URLS } from "../../../Utils";

import UmpireReg from "./Components/UmpireReg";

const Umpire = () => {
  const [umpire, setUmpire] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUmpireId, setSelectedUmpireId] = useState(null);

  const openEditModal = (id) => {
    setSelectedUmpireId(id);
    setShowModal(true);
  };

  const closeEditModal = () => {
    setShowModal(false);
    setSelectedUmpireId(null);
  };

  const getUmpire = async () => {
    try {
      const parkId = 0;
      const response = await axios.get(API_URLS.GET_ALL_UMPIRES, {
        params: { parkId },
      });
      setUmpire(response.data.data.result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getUmpire();
  }, []);

  return (
    <div className="container">
      <div className="site-content">
        <h2 style={{ color: "black", fontWeight: "bold", textAlign: "center" }}>
          Umpire Management
        </h2>
        <div className="tabs_container">
          <table>
            <thead>
              <tr>
                <th>First Name</th>
                <th style={{ textAlign: "center" }}>Last Name</th>
                <th style={{ textAlign: "center" }}>Email</th>
                <th style={{ textAlign: "center" }}>Phone No</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {umpire.map((umpire) => (
                <tr key={umpire.UID}>
                  <td>{umpire.FirstName}</td>
                  <td style={{ textAlign: "center" }}>{umpire.LastName}</td>
                  <td style={{ textAlign: "center" }}>{umpire.EmailId}</td>
                  <td style={{ textAlign: "center" }}>{umpire.PhoneNo}</td>
                  <td>
                    <button
                      className="btn btn_primary text_upp"
                      onClick={() => openEditModal(umpire.UID)}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {showModal && (
          <UmpireReg
            isOpen={showModal}
            onClose={closeEditModal}
            Id={selectedUmpireId}
            onUpdate={getUmpire}
          />
        )}
      </div>
    </div>
  );
};

export { Umpire };
