import React from "react";
import { useEffect } from "react";
import { Fragment } from "react";
import { useState } from "react";
import { API_URLS } from "../../../Utils";
import axios from "axios";
import "./AllPlayers.css";
import { Alert } from "../../../components/Alert";
const PlayerTable = ({ players, onDelete }) => {
  return (
    <div className="tabs_container">
      <table>
        <thead>
          <tr>
            <th>First Name</th>
            <th>Last Name</th>
            <th>Is Anonymous</th>
            <th>Player Number</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {players?.map((player) => (
            <tr key={player?.id}>
              <td>{player?.FirstName}</td>
              <td>{player?.LastName}</td>
              <td>{player?.isAnonymous ? "Yes" : "No"}</td>
              <td>{player?.UniqueNumber}</td>
              <td>
                <button
                  className="btn btn_primary text_upp"
                  onClick={() => onDelete(player?.PlayerId)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
const Pagination = ({
  itemsPerPage,
  totalItems,
  currentPage,
  onPageChange,
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const pageNumbers = [];
  const maxPageNumbersToShow = 5;

  const startPage = Math.max(
    currentPage - Math.floor(maxPageNumbersToShow / 2),
    1
  );
  const endPage = Math.min(startPage + maxPageNumbersToShow - 1, totalPages);

  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  return (
    <nav>
      <ul className={`pagination`}>
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button onClick={() => onPageChange(1)} className="page-link">
            First
          </button>
        </li>
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <button
            onClick={() => onPageChange(currentPage - 1)}
            className="page-link"
          >
            Previous
          </button>
        </li>
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <button onClick={() => onPageChange(number)} className="page-link">
              {number}
            </button>
          </li>
        ))}
        <li
          className={`page-item ${
            currentPage === totalPages ? "disabled" : ""
          }`}
        >
          <button
            onClick={() => onPageChange(currentPage + 1)}
            className="page-link"
          >
            Next
          </button>
        </li>
        <li
          className={`page-item ${
            currentPage === totalPages ? "disabled" : ""
          }`}
        >
          <button
            onClick={() => onPageChange(totalPages)}
            className="page-link"
          >
            Last
          </button>
        </li>
      </ul>
    </nav>
  );
};

const AllPlayers = () => {
  const [players, setPlayers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const handleSearch = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to the first page on search
  };
  const itemsPerPage = 15;
  const handleDeleteClick = (id) => {
    setSelectedPlayerId(id);
  };
  const handleDelete = async (id) => {
    try {
      await axios.delete(API_URLS.DELET_PLAYER_PERMENANTELY, {
        params: { PlayerId: id },
      });
      setPlayers(players.filter((player) => player.PlayerId !== id));
      setSelectedPlayerId(null);
      alert("Player Deleted Successfully");
    } catch (error) {
      console.error("Error deleting player:", error);
      alert("Failed to delete player.");
    }
  };
  const confirmDelete = async () => {
    if (selectedPlayerId) {
      await handleDelete(selectedPlayerId);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  const filteredPlayers = players.filter((player) =>
    `${player.FirstName} ${player.LastName}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const indexOfLastPlayer = currentPage * itemsPerPage;
  const indexOfFirstPlayer = indexOfLastPlayer - itemsPerPage;
  const currentPlayers = filteredPlayers.slice(
    indexOfFirstPlayer,
    indexOfLastPlayer
  );
  const getPlayerHandler = async () => {
    setLoading(true);
    try {
      const {
        data: { data },
      } = await axios.get(API_URLS.GET_ALL_PLAYER, {
        params: {
          TeamId: 1,
        },
      });
      setPlayers(data?.result);
      setLoading(false);
    } catch (error) {
      alert(error.response.data.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    getPlayerHandler();
  }, []);
  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {" "}
        <div className="spinner" />
      </div>
    );
  }
  return (
    <Fragment>
      <h2 style={{ color: "black", fontWeight: "bold", textAlign: "center" }}>
        Delete Extra Player
      </h2>
      <div className="container">
        <div className="search-bar">
          <input
            type="text"
            className="search-input"
            placeholder="Search Player..."
            value={searchQuery}
            onChange={handleSearch}
          />
        </div>
        <PlayerTable players={currentPlayers} onDelete={handleDeleteClick} />
        <Pagination
          itemsPerPage={itemsPerPage}
          totalItems={filteredPlayers?.length}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
        {selectedPlayerId && (
          <Alert
            message="Are you sure want to delete this player?"
            confirmPress={confirmDelete}
            cancelPress={() => setSelectedPlayerId(null)}
          />
        )}
      </div>
    </Fragment>
  );
};

export { AllPlayers };
